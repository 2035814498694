<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="광고 관리명"
            label-for="title"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PaperclipIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="title"
                placeholder="광고 관리명"
                v-model="banner.title"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="노출 시작일"
            label-for="startDate"
          >
            <b-form-datepicker
              id="startDate"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              v-model="banner.startDate"
              locale="kr"
              placeholder="시작일 선택"
              calendar-width="100%"
              :hide-header="true"
            />
            <!-- <b-form-text class="text-danger"> Enter a Valid Email </b-form-text> -->
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="노출 종료일"
            label-for="endDate"
          >
            <b-form-datepicker
              id="endDate"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              v-model="banner.endDate"
              locale="kr"
              placeholder="종료일 선택"
              calendar-width="100%"
            />

            <!-- <b-form-text class="text-danger"> Enter a Valid Email </b-form-text> -->
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="광고 URL"
            label-for="openUrl"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ExternalLinkIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="openUrl"
                placeholder="광고 URL"
                v-model="banner.openUrl"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="배너파일"
            label-for="basicInput"
          >
            <b-aspect
              :aspect="aspect"
              class="border border-primary rounded"
            >
              <b-img
                ref="refPreviewEl"
                rounded
                :src="banner.image"
                fluid-grow
              />
            </b-aspect>
            <div class="mt-1 text-right">
              <b-button
                variant="danger"
                @click="$refs.refInputEl.$el.click()"
              >
                업로드
              </b-button>
              <b-form-file
                ref="refInputEl"
                id="image"
                v-model="bannerFile"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="inputImageRenderer"
              />
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="배너위치">
            <b-input-group class="input-group-merge">
              <b-form-select
                v-model="banner.location"
                :options="options"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <div class="my-1">
            <b-form-checkbox
              v-model="banner.isDisplay"
              checked="true"
              name="check-button"
              switch
              inline
            >
              배너 노출
            </b-form-checkbox>
          </div>
        </b-col>

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            @click.prevent="submitForm"
          >
            저장
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            @click.prevent="resetForm"
          >
            취소
          </b-button>
          <!-- 목록 -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            class="float-right"
            @click="$router.go(-1)"
          >
            목록
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
  import {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormDatepicker,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormText,
    BFormCheckbox,
    BFormSelect,
    BAspect,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
  import { ref } from '@vue/composition-api';

  export default {
    components: {
      BCard,
      BMedia,
      BAvatar,
      BCardText,
      BMediaAside,
      BMediaBody,
      BForm,
      BLink,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      BFormDatepicker,
      BFormDatalist,
      BFormInvalidFeedback,
      BInputGroup,
      BInputGroupPrepend,
      BFormValidFeedback,
      BFormText,
      BFormCheckbox,
      BFormSelect,
      BAspect,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        aspect: '3:1',
        config: {},
        banner: {
          title: '',
          startDate: '',
          endDate: '',
          openUrl: '',
          location: null,
          image: null,
          isDisplay: true,
        },
        bannerFile: null,
        options: [
          { value: null, text: '배너 위치 선택' },
          { value: 'main', text: '메인 하단( 배너롤링 )' },
          { value: 'main-top', text: '메인 상단' },
          { value: 'side-bottom', text: '사이드메뉴 하단' },
        ],
      };
    },
    created() {
      this.fetchData();
    },
    methods: {
      resetImage() {
        this.bannerFile = null;
      },

      async fetchData() {
        // const bannerId = this.$route.params.id;
        // console.log(bannerId);
        // this.$http.get(`/menu/${menuid}`).then(res => {
        //   this.config = res.data;
        // });
      },
      resetForm() {
        this.banner = {
          title: '',
          startDate: '',
          endDate: '',
          openUrl: '',
          location: null,
          image: null,
          isDisplay: true,
        };

        resetImage();
      },
      submitForm() {
        const formData = new FormData();

        // formData.append('_method', 'PATCH');
        // Append the file
        formData.append('image', this.bannerFile);
        formData.append('imageOld', this.banner.image);
        formData.append('title', this.banner.title);
        formData.append('startDate', this.banner.startDate);
        formData.append('endDate', this.banner.endDate);
        formData.append('openUrl', this.banner.openUrl);
        formData.append('location', this.banner.location);
        formData.append('isDisplay', this.banner.isDisplay);

        // console.log(JSON.stringify(this.banner));
        // console.log(formData);

        // return;
        // axios 파일업로드 헤더 설정
        this.$http.defaults.headers.post['Content-Type'] =
          'multipart/form-data';

        this.$http.post(`/banner/register`, formData).then(res => {
          this.$router.replace(`/banner/list`);
        });
      },
    },
    setup() {
      const refInputEl = ref(null);
      const refPreviewEl = ref(null);

      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          refPreviewEl.value.src = base64;
        }
      );

      return {
        refInputEl,
        refPreviewEl,
        inputImageRenderer,
      };
    },
  };
</script>

<style lang="scss">
  // @import '@core/scss/vue/libs/vue-select.scss';
  // @import '@core/scss/vue/libs/quill.scss';
  @import '@core/scss/vue/pages/page-blog.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
